import React from 'react';
import Page from "../components/common/page"

import { ContentRow } from "../components/common/shared-components/layouts"
import PrivacyElement from '../components/page-components/privacy';


const CONTENT_ROWS = [
    [<PrivacyElement/>]
];


const Privacy = () => {
    return(
        <Page title="board">
            {CONTENT_ROWS.map((components, key)=>
            <ContentRow key={key} components={components} />
            )}
        </Page>)
};

export default Privacy;