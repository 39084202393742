import React from "react"
import { Headline, Text } from "../../common/headline"
import { Container, Content, Element } from "../agb/index"
import { useIntl } from "gatsby-plugin-react-intl"
import i18n from "./i18n"

import { BsDot } from "react-icons/bs"

const PrivacyElement = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        <Headline type="h3">{intl.formatMessage(i18n.headline)} </Headline>
        <Element
          headline={intl.formatMessage(i18n.principletitle)}
          text1={intl.formatMessage(i18n.principletext1)}
        />
        <Element
          headline={intl.formatMessage(i18n.collectiontitle)}
          text1={intl.formatMessage(i18n.collectiontext1)}
        />
        <Element
          headline={intl.formatMessage(i18n.transfertitle)}
          text1={intl.formatMessage(i18n.transfertext1)}
          text2={intl.formatMessage(i18n.transfertext2)}
        />
        <Bullet
          headline={intl.formatMessage(i18n.workingtitle)}
          text1={intl.formatMessage(i18n.workingtext1)}
          text2={intl.formatMessage(i18n.workingtext2)}
          text3={intl.formatMessage(i18n.workingtext3)}
          text4={intl.formatMessage(i18n.workingtext4)}
          text5={intl.formatMessage(i18n.workingtext5)}
          text6={intl.formatMessage(i18n.workingtext6)}
          text7={intl.formatMessage(i18n.workingtext7)}
        />
        <Element
          headline={intl.formatMessage(i18n.usagetitle)}
          text1={intl.formatMessage(i18n.usagetext1)}
          text2={intl.formatMessage(i18n.usagetext2)}
          text3={intl.formatMessage(i18n.usagetext3)}
        />
        <Element
          headline={intl.formatMessage(i18n.newslettertitle)}
          text1={intl.formatMessage(i18n.newslettertext1)}
        />
        <Element
          headline={intl.formatMessage(i18n.rightstitle)}
          text1={intl.formatMessage(i18n.rightstext1)}
        />
        <Headline type="p" fontWeight="600">
          info@tmc-home.de
        </Headline>
      </Content>
    </Container>
  )
}

const Bullet = ({
  headline,
  text1,
  text2,
  text3,
  text4,
  text5,
  text6,
  text7,
}) => {
  return (
    <div>
      <Headline type="h4" margin=" 0.3rem auto">
        {headline}
      </Headline>
      <Text margin="0.8rem auto">{text1} </Text>
      <Text>
        {" "}
        <BsDot />
        {text2}{" "}
      </Text>
      <Text>
        <BsDot />
        {text3}{" "}
      </Text>
      <Text>
        <BsDot />
        {text4}{" "}
      </Text>
      <Text>
        <BsDot />
        {text5}{" "}
      </Text>
      <Text>
        <BsDot />
        {text6}{" "}
      </Text>
      <Text margin="0.8rem auto">{text7} </Text>
    </div>
  )
}

export default PrivacyElement
