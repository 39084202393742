import { defineMessages } from "react-intl"

const scope = "privacy"

export default defineMessages({
  headline: {
    id: `${scope}.title`,
    defaultMessage: "Datenschutzerklärung",
  },

  principletitle: {
    id: `${scope}.principletitle`,
    defaultMessage: "Grundsatz",
  },
  principletext1: {
    id: `${scope}.principletext1`,
    defaultMessage:
      "Die Betreiber der Seiten von www.tmc-home.de nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.",
  },

  collectiontitle: {
    id: `${scope}.collectiontitle`,
    defaultMessage: "Erheben von Daten",
  },
  collectiontext1: {
    id: `${scope}.collectiontext1`,
    defaultMessage:
      "Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten). Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.",
  },

  transfertitle: {
    id: `${scope}.transfertitle`,
    defaultMessage: "Übermitteln von Daten",
  },
  transfertext1: {
    id: `${scope}.transfertext1`,
    defaultMessage:
      "Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der Vertragsabwicklung notwendig ist etwa an die mit der Lieferung der Ware betrauten Unternehmen oder das mit der Zahlungsabwicklung beauftragte Kreditinstitut.",
  },
  transfertext2: {
    id: `${scope}.transfertext2`,
    defaultMessage:
      "Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdrückliche Einwilligung etwa zu Zwecken der Werbung erfolgt nicht.",
  },

  workingtitle: {
    id: `${scope}.workingtitle`,
    defaultMessage: "Datenverarbeitung auf dieser Internetseite",
  },
  workingtext1: {
    id: `${scope}.workingtext1`,
    defaultMessage:
      "Wir erheben und speichern automatisch Informationen in Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:",
  },
  workingtext2: {
    id: `${scope}.workingtext2`,
    defaultMessage: "Browsertyp/ Browserversion",
  },
  workingtext3: {
    id: `${scope}.workingtext3`,
    defaultMessage: "verwendetes Betriebssystem",
  },
  workingtext4: {
    id: `${scope}.workingtext4`,
    defaultMessage: "Referrer URL",
  },
  workingtext5: {
    id: `${scope}.workingtext5`,
    defaultMessage: "Hostname des zugreifenden Rechners",
  },
  workingtext6: {
    id: `${scope}.workingtext6`,
    defaultMessage: "Uhrzeit der Serveranfrage",
  },
  workingtext7: {
    id: `${scope}.workingtext7`,
    defaultMessage:
      "Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.",
  },

  cookiestitle: {
    id: `${scope}.cookiestitle`,
    defaultMessage: "Cookies",
  },
  cookiestext1: {
    id: `${scope}.cookiestext1`,
    defaultMessage:
      "Die Internetseiten verwenden teilweise so genannte Cookies. Diese dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.",
  },

  usagetitle: {
    id: `${scope}.usagetitle`,
    defaultMessage:
      "Datenschutzerklärung für die Nutzung von Facebook-Plugins (Like-Button)",
  },
  usagetext1: {
    id: `${scope}.usagetext1`,
    defaultMessage:
      "Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem „Like-Button“ („Gefällt mir“) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier: http://developers.facebook.com/docs/plugins/. Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten.",
  },
  usagetext2: {
    id: `${scope}.usagetext2`,
    defaultMessage:
      "Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von facebook unter http://de-de.facebook.com/policy.php",
  },
  usagetext3: {
    id: `${scope}.usagetext3`,
    defaultMessage:
      "Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook- Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.",
  },

  newslettertitle: {
    id: `${scope}.newslettertitle`,
    defaultMessage: "Newsletter",
  },
  newslettertext1: {
    id: `${scope}.newslettertext1`,
    defaultMessage:
      "Wenn Sie den auf der Webseite angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine eMail-Adresse sowie Informationen, welche uns Überprüfung gestatten, dass Sie der Inhaber der angegebenen Email-Adresse sind. Weitere Daten werden nicht erhoben. Die erteilte Einwilligung zur Speicherung der Daten, der Email-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen.",
  },

  rightstitle: {
    id: `${scope}.rightstitle`,
    defaultMessage: "Auskunftsrecht, Löschung, Sperrung",
  },
  rightstext1: {
    id: `${scope}.rightstext1`,
    defaultMessage:
      "Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter folgender E-Mail-Adresse an uns wenden:",
  },
})
